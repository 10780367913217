@import '~antd/dist/antd.css';

.tlo-content {
	margin: 0 auto;
	padding: 3em 0;
	max-width: calc(100% - 4em);
	width: 400px;
}

.tlo-content-wider {
	width: 600px;
}

.tlo-center {
	text-align: center;
}

body {
	font-size: 16px;
}

.card {
	text-align: center;
	position: relative;
	padding-top: 56.25%;
	background: url(https://cdn.ze3kr.com/6T-behmofKYLsxlrK0l_MQ/6c5456be-c944-40f1-710f-43bd42fff901/extra) no-repeat;
	background-size: contain;
	margin: 1em 0;
}

.card-content {
	border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;
}

.card-content .ant-typography {
	color: #fff;
	font-family: ocr-a-std, monospace;
	font-weight: 400;
	font-style: normal;
}

#card-num {
	margin: 33% 0 0 0;
	text-align: center;
}

#card-exp {
	font-size: 90%;
	margin: 0 0 0 20%;
	text-align: left;
}

#card-holder {
	font-size: 90%;
	margin: 0 0 0 10%;
	text-align: left;
}

.card-transaction {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
}

.card-extra {
	display: flex;
	justify-content: space-between;
	min-width: 200px;
}

/* Mobile */
@media (max-width: 640px) {
	.card-transaction {
		flex-direction: column;
	}
	.card-extra {
		min-width: 100%;
	}
}

@media (max-width: 420px) {
	#card-num {
		font-size: 20px;
	}
	#card-exp, #card-holder {
		font-size: 80%;
	}
	#card-exp {
		margin: 0 0 0 15%;
	}
}

@media (max-width: 380px) {
	#card-num {
		font-size: 16px;
	}
	#card-exp, #card-holder {
		font-size: 60%;
	}
	#card-exp {
		margin: 0 0 0 10%;
	}
}

.extra-info {
	margin-top: 1em;
}
